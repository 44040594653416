import React from "react"
import Lightbox from "react-image-lightbox"
import Img from "gatsby-image"

import { Grid, Paper } from "@material-ui/core"
import { ZoomIn } from "@material-ui/icons"
const ImgLightbox = ({ imgObj }) => {
  const imgArray: Array<string> = []
  const thumbArray = []
  imgObj.forEach(element => {
    imgArray.push(element.full.src)
    thumbArray.push(element.thumb)
  })
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + imgArray.length - 1) % imgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % imgArray.length
    setImgIndex(newIndex)
  }

  return (
    <React.Fragment>
      <Grid container spacing={3} justify="center">
        {thumbArray.map((thumbItem, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={`imggallery-${index}`}>
            <Paper elevation={4} style={{ overflow: "hidden" }}>
              <div
                className="gallery-image-wrapper"
                onClick={() => openLightBox(index)}
              >
                <Img fluid={thumbItem} />
                <div className="overlay">
                  <ZoomIn />
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={imgArray[imgIndex]}
          nextSrc={imgArray[(imgIndex + 1) % imgArray.length]}
          prevSrc={imgArray[(imgIndex + imgArray.length - 1) % imgArray.length]}
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </React.Fragment>
  )
}
export default ImgLightbox
