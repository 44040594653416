import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Box } from "@material-ui/core"

import Layout from "../components/layout/main"
import AnasayfaCarousel from "../components/global/carousel/anasayfacarousel"
import ImgLightbox from "../components/global/lightbox/lightbox"
import Seo from "../components/global/seo/seo.jsx"
function Galeri() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativeDirectory: { eq: "filtre-galeri" }
          ext: { in: [".jpg", ".png"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)

  return (
    <Layout>
      <Seo
        title="Galeri - Paslanmaz Filtre Erez Kaynak " 
        description="Emiş filtresi Silindir Saç Filtre İmalatı Seperatör Filtresi Silindir Paslanmaz Filtre İmalatı Seperatör Elek Filtresi Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Paslanmaz Silindir Filtre Pislik Tutucu Paslanmaz Filtre Silindir Paslanmaz"
        keywords="erez kaynak galeri, paslanmaz filtre resimleri, elek resimleri"
        url="galeri"
      />
      <Box mt={2} mb={6}>
        <AnasayfaCarousel />
      </Box>

      <ImgLightbox imgObj={images} />
    </Layout>
  )
}
export default Galeri
